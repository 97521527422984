.hero-icon {
  display: flex;
  flex-direction: column;
  align-items: center;

  .icon {
    width: 25px; /* Adjust the size as needed */
    height: 25px; /* Adjust the size as needed */
    margin-bottom: 10px;
    color: #2f1a60;
    animation: jump .5s linear alternate infinite; /* Add rotation animation */
    animation: twinkle .8s linear alternate infinite;
  }

  @keyframes jump {
    0%   {transform: translate3d(0,0,0) scale3d(1,1,1);}
    40%  {transform: translate3d(0,30%,0) scale3d(.7,1.5,1);}
    100% {transform: translate3d(0,100%,0) scale3d(1.5,.7,1);}
    }

    @keyframes twinkle {
	   50% {
		  transform: scale(0.5);
		  opacity: 0.5;
	   }
    }
}
